
// Neat Overrides
///////////////////////////////////////////////////////////////////////////////
$column: 90px;
$gutter: 30px;
$grid-columns: 12;
$max-width: 1088em;

// Neat Breakpoints
///////////////////////////////////////////////////////////////////////////////
$big-screen: 1700em;
$medium-screen: 800;
$large-screen: 860em;

@mixin medium-screen-up {
    @media only screen and (min-width: $medium-screen) {
        @content;
    }
}

@mixin large-screen-up {
    @media only screen and (min-width: $large-screen) {
        @content;
    }
}


@mixin big-screen-up {
    @media only screen and (min-width: $big-screen) {
        @content;
    }
}


@mixin medium-screen-range {
    @media only screen and (min-width: $medium-screen) and (max-width: $big-screen) {
        @content;
    }
}


@mixin medium-screen-up {
    @media only screen and (min-width: $medium-screen) {
        @content;
    }
}


@mixin small-screen {
    @media only screen and (max-width: $medium-screen) {
        @content;
    }
}

@mixin outer-container {
    margin: 0 auto;

    max-width: 90em;

    @include medium-screen-up {
        margin: 0 3em;
    }
}
