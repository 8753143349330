@import "bitters/bitters";
@import "monokai";


$logo-green: #81CC78;

$light-text: white;
$dark-text: black;

$darker-green: #45993D;
$lime-green: #C7FF00;
$purple: #7C28FF;
$blue-purple: #6153CC;
$grey: #F0F0F0;

$header-img-height: 70px;
$logo-height: 40px;

// copied from bootstrap
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

table.rouge-table {
    table-layout: auto;
}


#skiptocontent a {
	padding: 10px;
	position: absolute;
	top: -100px;
	left: 0px;
	color: white;
	background: black;

	&:focus {
		left: 0px;
		top: 0px;
	}
}

body {
	margin: 0;
    width: 100%;
    background-color: $base-body-color;
}

.site-header {
    a {
        margin-left: 0.5em;
    }

    padding: 1em 1em 0 1em;

    .site-nav {
        .nav-trigger, label {
            display: none;
        }
        flex-grow: 10;
        text-align: right;

        .trigger {
            display: flex;
            justify-content: end;
            align-items: end;
            flex-direction: row;
        }


        a {
            display: block;
            margin-left: 1.5em;
        }
    }

    .wrapper {
        @include outer-container;
        padding: 0em 0.3em;
        text-align: left;

        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    &:after {
        content: '';
        display: block;
        margin: 1em auto 0 auto;
        padding: 1em 0 0 0;
        background-image: url(/resource/colourful.png);
        border-radius: 0.5em;
        background-size: cover;
        clear: both;

        @include outer-container;
    }

    .site-title {
        @extend h1;
        color: $header-text-color;

        font-family: $site-title-font-family;
        letter-spacing: 0.2em;
        display: inline;
        align-self: center;
    }


    // page links

    > a {
        @include medium-screen-up {
            float: right;
        }

        height: $logo-height;
        margin-top: ($header-img-height - $logo-height) / 2;
        margin-right: 20px;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
    }
}

.outer {
	@include medium-screen-range {
		@include outer-container;
		padding: 1em;
	}

	@include small-screen {
		padding: 0.2em;
	}
}

main {
    @include outer-container;
}

article {
	margin: 1em;
	overflow: auto;

    &.post {
        border: thin solid $light-orange;
        border-radius: 0.5em;
        padding: 0.5em;
    }

	> {
		header {
            nav {
                display: flex;
                justify-content: space-between;
                margin: 0 0.1em;

                aside a {
                    margin-left: 0.3em;
                }
            }

			&::after {
				content: ".";
				display: block;
				width: 100%;
				height: 3px;
				background-size: cover;
				background-image: url(/resource/colourful.png);

                border-radius: 0.5em;
				clear: left;
			}

			h1 a {
                color: $header-text-color;
			}
		}
	}

	.post-content {
        clear: both;
		margin-top: 0.3em;

        a {
            text-decoration: underline;
        }

		img.icon {
			float: right;
			margin: 0.2em;
		}

		ul {
			@extend %default-ul;
		}

		ol {
			@extend %default-ol;
		}

		pre > code {
			display: block;
		}

        .rouge-table {
            margin: 0;

            td {
                padding: 0 0.2em;
            }
        }

        .highlight pre {
            margin: 0.3em;
			border-radius: 5px;
        }

		code {
            padding: 0 0.5em;
			color: $blue-purple;
            background-color: $light-gray;
			font: monospace;
		}
	}

    footer a {
        text-decoration: underline;
    }
}

footer.site-footer {
    .footer-col-wrapper {
        display: flex;
        justify-content: space-between;
    }
}
