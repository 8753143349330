// Typography
///////////////////////////////////////////////////////////////////////////////
$helvetica: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Electrolize&family=Orbitron:wght@400..900&display=swap');
$electrolize: "Electrolize", sans-serif;

$base-font-family: $electrolize;
$header-font-family: $electrolize;

@font-face {
    font-family: 'zorfich';
    src: url('/resource/ZORFICH.woff') format('woff');
}

$site-title-font-family: zorfich, sans-serif;


// Sizes
///////////////////////////////////////////////////////////////////////////////
$base-font-size: 1em;
$base-line-height: $base-font-size * 1.5;
$base-border-radius: em(3);

// Colors
///////////////////////////////////////////////////////////////////////////////

$blue: #7f9be8;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #DDD;
$light-red: #FBE3E4;
$light-yellow: #FFF6BF;
$light-green: #cfe87f;
$light-pink: #cc7fe8;
$light-purple: #977fe8;
$light-orange: #e8977f;

// Body Color
$base-body-color: $dark-gray;

//  Font Colors
$base-font-color: $light-red;
$base-accent-color: $light-pink;
$header-text-color: $light-orange;

//  Text Link Colors
$base-link-color: $base-accent-color;
$hover-link-color: darken($base-accent-color, 15);

// Border color
$base-border-color: $light-gray;

// Flash Colors
$error-color: $light-red;
$notice-color: $light-yellow;
$success-color: $light-green;
